#root .banner {
  box-sizing: border-box;
  min-height: 0;
}

#root .subheading {
  display: none;
}

@media screen and (min-width: 640px) {
  #root .banner {
      min-height: 18vh;
  }

  #root .subheading {
    display: inherit;
  }
}
